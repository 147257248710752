import React from 'react'

const Infographics = () => {
  return (
    <div>
    <h1>Coming soon!</h1>
    </div>
  )
}

export default Infographics