import React from 'react'

const Games = () => {
  return (
    <div>
    <h1>Coming soon!</h1>
    
    </div>
  )
}

export default Games